/**
 * Loop through all elements in a collection
 * @param elements Collection or NodeList of elements
 * @param action Action to perform on each element
 */
export function loopThroughElements(elements: NodeListOf<Element> | HTMLCollectionOf<Element>, action: (HTMLElement: HTMLElement) => any): void {
    for (let i = 0; i < elements.length; i++) {
        const el = <HTMLElement>elements[i];
        action(el);
    }
}
