/**
 * Invoke a callback after the document has reached ready state.
 * @param callback Action to take when document is ready
 */
export function onReady(callback: () => void): void {
    if (document.readyState === 'loading') {
        document.addEventListener('DOMContentLoaded', callback);
    } else {
        callback();
    }
}
