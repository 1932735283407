import { FarmhandMcw } from './components/farmhand-mcw';
import * as jQuery from 'jquery';
declare var window: any;
const mdc = require('material-components-web');

// jQuery
//window.$ = window.jQuery = jQuery;
//import 'script-loader!../../../node_modules/jquery/dist/jquery.min.js';

// using script loader for legacy scripts that need to be in global scope
import 'script-loader!./components/details-shim.js';
import 'script-loader!./legacy/lazysizes.js';
import 'script-loader!./legacy/form-input-handling.js';
import 'script-loader!./legacy/product-table-tooltip.js';
import { bindComponentsToClass } from './utilities/bind-components-to-class';
import { onReady } from './utilities/on-ready';
import { TabPager } from './components/tab-pager';

// Custom code - modularized
window.farmhandMcw = new FarmhandMcw();
window.mdc = mdc;

onReady(() => {
    window.tabPager = new TabPager();
})