import { MDCTabBar } from '@material/tab-bar';
import { onReady } from './../utilities/on-ready';
import { loopThroughElements } from './../utilities/loop-through-elements';
import { getFragment } from './../utilities/get-fragment';

export class TabPager {
    private tabBar: MDCTabBar;

    constructor() {
        onReady(this.initialize);
    }

    initialize = (): void => {
        const tabBarElement: HTMLElement = document.querySelector('.mdc-tab-bar.mdc-tab-bar--pager');
        if (tabBarElement) {
            const contentParent: HTMLElement = document.getElementById(tabBarElement.dataset.for);
            this.tabBar = new MDCTabBar(tabBarElement);
            this.tabBar.listen('MDCTabBar:activated', e => this.onTabChange(e, contentParent));
        }
        this.goToFragment();
        window.addEventListener('hashchange', this.goToFragment, false);
    };

    onTabChange = (event: any, contentParent: HTMLElement): void => {
        event.preventDefault();
        const contentEls = contentParent.querySelectorAll('.content');
        // Hide currently-active content
        loopThroughElements(contentParent.getElementsByClassName('content--active'), el => el.classList.remove('content--active'));
        // Show content for newly-activated tab
        contentEls[event.detail.index].classList.add('content--active');
    };

    goToFragment = (): void => {
        const fragment: string = getFragment();
        if (this.tabBar) {
            const adapter = (<any>this.tabBar).foundation_.adapter_;
            if (fragment) {
                const tabIndex = adapter.getIndexOfTabById(fragment);
                if (tabIndex > -1) {
                    adapter.setActiveTab(tabIndex);
                    window.scrollTo(0, 0);
                    return;
                }
            }
            // default to first tab if not specified in fragment
            adapter.setActiveTab(0);
        }
    };
}