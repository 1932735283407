import { MDCRipple } from '@material/ripple/index';
import { MDCList } from '@material/list';
import { MDCTextField } from '@material/textfield';
import { MDCChipSet, MDCChip } from '@material/chips';
import { MDCSelect } from '@material/select';
import { MDCCheckbox } from '@material/checkbox';
import { MDCSnackbar } from '@material/snackbar';
import { MDCSlider } from '@material/slider';
import { MDCSwitch } from '@material/switch';
import { MDCTab } from '@material/tab';
import { MDCTabBar } from '@material/tab-bar';
import { MDCMenu } from '@material/menu';
import { MDCDrawer } from "@material/drawer";
import { MDCTopAppBar } from '@material/top-app-bar';
import { MDCDialog } from '@material/dialog';
import { loopThroughElements } from '../utilities/loop-through-elements';

declare let window: any;

export class FarmhandMcw {
    constructor() {
        this.waitForDocument();
    }

    waitForDocument = (): void => {
        if (document.readyState === "complete" || (document.readyState !== "loading" && !(document.documentElement as any).doScroll)) {
            this.initialize();
        } else {
            document.addEventListener("DOMContentLoaded", this.initialize);
        }
    };

    initialize = (): void => {
        this.attachElements(this.getClassesToAttach());
        this.bindToWindow();
        this.configureDrawer();
        this.configureCTADrawer();
    };

    getClassesToAttach = (): any => ({
        ".mdc-button": MDCRipple,
        ".mdc-list": MDCList,
        ".mdc-text-field": MDCTextField,
        ".mdc-chip-set": MDCChipSet,
        ".mdc-chip": MDCChip,
        ".mdc-select": MDCSelect,
        ".mdc-checkbox": MDCCheckbox,
        ".mdc-snackbar": MDCSnackbar,
        ".mdc-slider": MDCSlider,
        ".mdc-switch": MDCSwitch,
        ".mdc-tab-bar": MDCTabBar,
        ".mdc-tab": MDCTab,
        ".mdc-menu": MDCMenu
    });

    attachments: any[] = [];
    components: any[] = [];

    attachElements = (classes): void => {
        Object.keys(classes).forEach(querySelector => {
            if (classes[querySelector]) {
                const attachment = classes[querySelector];
                const elements = document.querySelectorAll(querySelector);

                if (elements) {
                    loopThroughElements(elements, element => {
                        const component = attachment.attachTo(element);
                        this.components.push(component);
                        if (element.dataset.mdcId) {
                            this.attachments[element.dataset.mdcId] = component;
                        }

                        if (attachment === MDCChip) {
                            this.handleChipToggle(component);
                        }
                    });
                }
            }
        });
    };

    handleChipToggle = (chip: MDCChip): void => {
        const rootElement = chip.root as HTMLElement;
        rootElement.onclick = () => {
            const isSelected = rootElement.getAttribute('data-selected') === 'true';
            rootElement.setAttribute('data-selected', String(!isSelected));
            if (!isSelected) {
                rootElement.classList.add('mdc-chip--selected');
            } else {
                rootElement.classList.remove('mdc-chip--selected');
            }
        };
    };

    bindToWindow = (): void => {
        window.mdwc = {
            _open: obj => obj.open ? obj.open() : null,
            _close: obj => obj.close ? obj.close() : null,
            snackbar: {
                open: querySelector => window.mdwc._open(new MDCSnackbar(document.querySelector(querySelector))),
                close: querySelector => window.mdwc._close(new MDCSnackbar(document.querySelector(querySelector)))
            },
            dialog: {
                open: querySelector => window.mdwc._open(new MDCDialog(document.querySelector(querySelector))),
                close: querySelector => window.mdwc._close(new MDCDialog(document.querySelector(querySelector)))
            },
            menu: {
                open: querySelector => new MDCMenu(document.querySelector(querySelector)).open = true,
                close: querySelector => new MDCMenu(document.querySelector(querySelector)).open = false
            }
        };
    };

    configureDrawer = (): void => {
        const drawerEl = document.querySelector(".mdc-drawer:not(.cta-drawer)");
        if (drawerEl) {
            const drawer = MDCDrawer.attachTo(drawerEl);
            const topAppBarEl = document.querySelector(".mdc-top-app-bar");
            const topAppBar = MDCTopAppBar.attachTo(topAppBarEl);
            topAppBar.setScrollTarget(document.querySelector(".main-content"));
            topAppBar.listen("MDCTopAppBar:nav", () => {
                drawer.open = !drawer.open;
            });
        }
    };

    configureCTADrawer = (): void => {
        const drawerEl = document.querySelector(".mdc-drawer.cta-drawer");
        if (drawerEl) {
            const drawer = MDCDrawer.attachTo(drawerEl);
            const ctaButton = document.querySelector(".mdc-button.cta-drawer-button");
            ctaButton.addEventListener("click", () => {
                drawer.open = !drawer.open;
                if (document.activeElement instanceof HTMLElement) {
                    document.activeElement.blur();
                }
            });
        }
    };

    bindRipples = (): void => {
        document.querySelectorAll('.mdc-button, .mdc-icon-button, .mdc-card__primary-action').forEach(el => {
            new MDCRipple(el);
        });
    };
}
